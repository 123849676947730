import React, { useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { useParams } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Spinner
} from "reactstrap";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import DisplayUploads from "components/Uploads/DisplayUploads";
import DisplayMultipleUploads from "components/Uploads/MultipleDisplayUploads";

function EditUser() {

  const { auth, user } = React.useContext(UserContext)
  const [userInfo, setUser] = React.useState([]);
  const [userProfileInfo, setUserInfo] = React.useState([]);


  const [notificationDescription, setNotificationDescription] = React.useState('');
  const [notificationTitle, setNotificationTitle] = React.useState('');


  const [uStatus, setUStatus] = React.useState('P');
  const [kycStatus, setKYCStatus] = React.useState('N');
  const [vip, setVIP] = React.useState(false);
  const [payment, setPayment] = React.useState('N');
  const [cipc, setCIPC] = React.useState('');
  const [userRole, setUserRole] = React.useState('');

  const [idupload, setIDUpload] = React.useState('')
  const [idupload2, setIDUpload2] = React.useState('')
  const [poaupload, setPOAUpload] = React.useState('')
  const [cipcupload, setCIPCupload] = React.useState('')

  const [adminUserid, setAdminUserid] = React.useState('')


  const [usdtWalletet, setusdtWalletet] = React.useState(0);
  const [btcWalletet, setbtcWalletet] = React.useState(0);
  const [zarWalletet, setzarWalletet] = React.useState(0);

  const [usdtProduct, setusdtProduct] = React.useState(0);
  const [zarProduct, setzarProduct] = React.useState(0);

  const [userCompany, setUserCompany] = React.useState('');
  const [adminUser, setAdminUser] = React.useState('');

  
  const [bankUpload, setBankUpload] = React.useState(null);


  // const [displayUserSpinner, setDisplayUserSpinner] = React.useState(true)
  const [displayUserSpinner, setDisplayUserSpinner] = React.useState(true);


  let { id } = useParams()

  React.useEffect(() => {

    if (auth != null) {
      if (!auth) {
        window.location.href = "/login"
      } else {
        console.log("productedt ", user.user)

        setAdminUser(user.user.productEdit)
        getUserInfo(user.user.userid)
        setAdminUserid(user.user.userid)
        getProducts()
        getSavedProducts(id)
        setNotificationsLoading(false)
      }
    }
  }, [auth])

  const getUserInfo = async (userid) => {
    var userObj = {
      id: id,
      userid: userid
    }
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/find-one-user-by-id`, userObj).then(async (res) => {

      if (res.data.status == 200) {
        //   setUserInfo(res.data.info)
        console.log("the userinfo is: ", res.data.userinfo)
        setUser(res.data.user)
        setUserInfo(res.data.userinfo)
        setPersonalLoading(false)
        setDisplayUserSpinner(false);
        console.log(res.data)
        setUStatus(res.data.user.u_status)
        setUserRole(user.user.u_role)
        setKYCStatus(res.data.user.fica_status)
        setVIP(res.data.user.vip)
        setPayment(res.data.user.payment_status)
        setCIPC(res.data.userinfo.cipc)
        setUserCompany(res.data.user.company)

        var images = await getAllImages(res.data.userinfo)

        setusdtWalletet(res.data.wallets.usdt)
        setzarWalletet(res.data.wallets.zar)
        setbtcWalletet(res.data.wallets.btc)

        setusdtProduct(res.data.products.usdt)
        setzarProduct(res.data.products.zar)
        setBalanceLoading(false)

        await axios
        .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: res.data.userinfo.bank_upload })
        .then(res2 => {
          setBankUpload(res2.data)
        })


      } else {
        window.location.href = "/users"
      }

    })
  }

  const getAllImages = async (info) => {

    var id = await axios
      .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.idupload })
      .then(res2 => {
        setIDUpload(res2.data)
      })


    var id2 = await axios
      .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.idupload2 })
      .then(res2 => {
        setIDUpload2(res2.data)
      })

    var poa = await axios
      .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.poaupload })
      .then(res2 => {
        setPOAUpload(res2.data)
      })

    var cipc = await axios
      .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.cipcupload })
      .then(res2 => {
        setCIPCupload(res2.data)
      })


    // var cm2 = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.cm2_upload })
    //   .then(res2 => {
    //     setcm2Cupload(res2.data)
    //   })

    // var cm22 = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.cm22_upload })
    //   .then(res2 => {
    //     setcm22Cupload(res2.data)
    //   })

    // var cm29 = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.cm29_upload })
    //   .then(res2 => {
    //     setcm29Cupload(res2.data)
    //   })

    // var cm9 = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.cm9_cor_upload })
    //   .then(res2 => {
    //     setcm9Cupload(res2.data)
    //   })

    // var businessResidence = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.business_residence_upload })
    //   .then(res2 => {
    //     setBusinessResidenceUpload(res2.data)
    //   })

    // var businessNameDocumentation = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.business_name_documentation_upload })
    //   .then(res2 => {
    //     setBusinessNameDocumentationUpload(res2.data)
    //   })

    // var taxRegistrationsUpload = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.tax_registrations_upload })
    //   .then(res2 => {
    //     setTAXRegistrationsUpload(res2.data)
    //   })

    // var resolutionUpload = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.resolution_upload })
    //   .then(res2 => {
    //     setResolutionUpload(res2.data)
    //   })

    // var bankStatement = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.bank_statement_upload })
    //   .then(res2 => {
    //     setBankStatementUpload(res2.data)
    //   })


    // var sourceFunds = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.source_of_funds_upload })
    //   .then(res2 => {
    //     setSourceOfFundsUpload(res2.data)
    //   })

    // var userUploads = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.user_uploads })
    //   .then(res2 => {
    //     setUserUploadsUpload(res2.data)
    //   })

    // var taxNumber = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.tax_number })
    //   .then(res2 => {
    //     setTAXNumberUpload(res2.data)
    //   })

    // var taxUpload = await axios
    //   .post(process.env.REACT_APP_API_BASE_URL + `getimage`, { image: info.tax_upload })
    //   .then(res2 => {
    //     setTAXUploadUpload(res2.data)
    //   })
  }

  const sendNotification = async (userid) => {
    var notificationObj = {
      userid: userid,
      title: notificationTitle,
      message: notificationDescription,
      adminUserid: adminUserid
    }

    console.log(notificationObj)

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-notification`, notificationObj).then((res) => {

      if (res.data.status == 200) {
        toast.success('Notification Sent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setNotificationDescription('')
        setNotificationTitle('')

      } else {
        toast.error('Unable to send Notification!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    })
  }

  const changeNotificationDescription = async (description) => {
    console.log(description)
    setNotificationDescription(description)
  }

  const changeNotificationTitle = async (title) => {
    setNotificationTitle(title)
  }


  const approveKYC = async (title) => {
    setNotificationTitle(title)
    const updatedInfoObj = {
      status: uStatus,
      ficastatus: kycStatus,
      ficaoption: 'F',
      userid: userInfo.userid,
      paymentStatus: payment,
      vip: vip,
      adminuser: adminUserid
    }

    axios.post(process.env.REACT_APP_BASE + "api/update-status", updatedInfoObj).then(async res => {

      if (res.data.status == 200) {
        // toastr.options.onHidden = function() { window.location.href="/user-list" } 

        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else
        toast.error('Unable To Update KYC Info', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      return false
    });
  }

  const optionGroup = [
    { label: "Email Confirmation Required", value: "L" },
    { label: "Active", value: "A" },
    { label: "Pending", value: "P" },
    { label: "Hard Lock", value: "HL" },
    { label: "Soft Locked", value: "SL" }
  ]
  const ficaStatus = [
    { label: "Required", value: "N" },
    { label: "Pending", value: "P" },
    { label: "Active", value: "A" },
  ]
  const ficaOption = [
    { label: "Required", value: "N" },
    { label: "Basic Fica (Deprecated)", value: "B" },
    { label: "Full Fica", value: "F" },
  ]
  const paymentOption = [
    { label: "Required", value: "N" },
    { label: "Pending", value: "P" },
    { label: "Active", value: "A" },
  ]
  const vipOption = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]

  const [declineIDUpload, setDeclineIDUpload] = React.useState(false);
  const [declineAddressUpload, setDeclineAddressUpload] = React.useState(false);
  const [declineCIPCCUpload, setDeclineCIPCCUpload] = React.useState(false);


  const [personalLoading, setPersonalLoading] = React.useState(true);
  const [balanceLoading, setBalanceLoading] = React.useState(true);
  const [accountStatusLoading, setAccountStatusLoading] = React.useState(true);
  const [notificationsLoading, setNotificationsLoading] = React.useState(true);

  const [declinedUploads, setDeclinedUploads] = React.useState(false);

  const [declinedNotes, setDeclinedNotes] = React.useState("");


  const [allProducts, setAllProducts] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [savedProducts, setSavedProducts] = useState([]);

  const [fieldsToDelcined, setFieldsToDecline] = useState([]);

    // Function to add a field to the fieldsToDeclined array
    const addFieldToDeclined = (field) => {
      setFieldsToDecline(prevFields => [...prevFields, field]);
    };

  const clickedDeclineButton = async (option) => {
    console.log("I have declined the upload ", declinedUploads)
    if (!declinedUploads) {

      setDeclinedUploads(true)
    }
    console.log("option clicked is ", option)

    if (option == "id") {
      console.log("I have declined the upload ID")
      setDeclineIDUpload(true)
      
      addFieldToDeclined("idupload");
    } else if (option == "poa") {
      addFieldToDeclined("poaupload");
      setDeclineAddressUpload(true)
    } else if (option == "cipc") {
      setDeclineCIPCCUpload(true)
      addFieldToDeclined("cipc");
    } else if (option == "cm22") {
      setDeclinedCM22Upload(true)
      addFieldToDeclined("cm22_upload");
    } else if (option == "cm2") {
      setDeclinedCM2Upload(true)
      addFieldToDeclined("cm2_upload");
    } else if (option == "cm9") {
      setDeclinedCM9Upload(true)
      addFieldToDeclined("cm9_cor_upload");
    } else if (option == "cm29") {
      setDeclinedCM29Upload(true)
      addFieldToDeclined("cm29_upload");
    } else if (option == "BusinessResidence") {
      setDeclinedBusinessResidenceUpload(true)
      addFieldToDeclined("business_residence_upload");
    } else if (option == "BusinessNameDocumentation") {
      setDeclinedBusinessNameDocumentationUpload(true)
      addFieldToDeclined("business_name_documentation_upload");
    } else if (option == "TAXRegistrations") {
      setDeclinedTAXRegistrationsUpload(true)
      addFieldToDeclined("tax_registrations_upload");
    } else if (option == "Resolution") {
      setDeclinedResolutionUpload(true)
      addFieldToDeclined("resolution_upload");
    } else if (option == "BankStatement") {
      setDeclinedBankStatementUpload(true)
      addFieldToDeclined("bank_statement_upload");
    }
    else if (option == "TAXNumber") {
      addFieldToDeclined("tax_number");
      setDeclinedTAXNumberUpload(true)
    }
    else if (option == "TAXUpload") {
      addFieldToDeclined("tax_upload");
      setDeclinedTAXUploadUpload(true)
    }
    else if (option == "SourceOfFunds") {
      addFieldToDeclined("source_of_funds_upload");
      setDeclinedSourceOfFundsUpload(true)
    }
    else if (option == "UserUploads") {
      addFieldToDeclined("user_uploads");
      setDeclinedUserUploadsUpload(true)
    }
  }

  const declineUploads = async (userid) => {
    console.log("user clicked on save declined uploades")
    if (declinedNotes === '') {
      toast.error('Please add a note in the notes section', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    if (!fieldsToDelcined && fieldsToDelcined <= 0) {
      toast.error('No uploaded selected', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      return false;
    }

    const declinedObj = {
      // idupload: declineIDUpload,
      // poaUpload: declineAddressUpload,
      // cipccUpload: declineCIPCCUpload,
      // notes: declinedNotes,
      // userid: userid

      "notes": declinedNotes,
      "userid": userid,
      "fieldsToDecline": fieldsToDelcined
    }

    console.log("fields to call is: ", declinedObj)
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}declined-kyc-uploads`, declinedObj).then((res) => {
      if (res.data.status === 200) {
        toast.success('Uploads declined Successfull', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error('Unable to decline uploads', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    })
  }

  const onNotesChanges = async (notes) => {
    // console.log(notes)
    setDeclinedNotes(notes)
  }

  const getProducts = async () => {

    await axios.get(`${process.env.REACT_APP_API_BASE_URL}all-products-unique`, {}).then((res) => {
      setAllProducts(res.data.Products)
      console.log("unique products is ", res.data.Products)
    })


  }

  const handleProductSelection = (productId) => {
    console.log(productId)
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const submitProductSelection = async (userid) => {
    console.log(selectedProducts, userid, userCompany)
    var productObj = {
      userid: userid,
      company: userCompany,
      products: selectedProducts
    }
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-user-products`, productObj).then(async (res) => {

      if (res.data.status == 200) {
        toast.success('User Products Updaeted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error('Unable to update User products', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    })
  }

  const getSavedProducts = async (userid) => {
    var userObj = {
      id: userid
    }
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}get-user-saved-products`, userObj).then((res) => {

      console.log("saved settings is " + id, res.data.data)
      setSelectedProducts(res.data.data)
      // var receivedProducts = res.data.data
      // for (let product of receivedProducts)
      // {
      //   var productId = product.productid
      //   if (selectedProducts.includes(productId)) {
      //     setSelectedProducts(selectedProducts.filter(id => id !== productId));
      //   } else {
      //     setSelectedProducts([...selectedProducts, productId]);
      //   }
      // }

    })

    setAccountStatusLoading(false)
  }


  // New KYC Requirements
  // Company
  const [declineCM2Upload, setDeclinedCM2Upload] = React.useState(false);
  const [declineCM22Upload, setDeclinedCM22Upload] = React.useState(false);
  const [declineCM29Upload, setDeclinedCM29Upload] = React.useState(false);
  const [declineCM9Upload, setDeclinedCM9Upload] = React.useState(false);
  const [declineBusinessResidenceUpload, setDeclinedBusinessResidenceUpload] = React.useState(false);
  const [declineBusinessNameDocumentationUpload, setDeclinedBusinessNameDocumentationUpload] = React.useState(false);
  const [declineTAXRegistrationsUpload, setDeclinedTAXRegistrationsUpload] = React.useState(false);
  const [declineResolutionUpload, setDeclinedResolutionUpload] = React.useState(false);
  const [declineBankStatementUpload, setDeclinedBankStatementUpload] = React.useState(false);


  const [cm2upload, setcm2Cupload] = React.useState('')
  const [cm22upload, setcm22Cupload] = React.useState(false);
  const [cm29upload, setcm29Cupload] = React.useState(false);
  const [cm9upload, setcm9Cupload] = React.useState(false);
  const [BusinessResidenceUpload, setBusinessResidenceUpload] = React.useState(false);
  const [BusinessNameDocumentationUpload, setBusinessNameDocumentationUpload] = React.useState(false);
  const [TAXRegistrationsUpload, setTAXRegistrationsUpload] = React.useState(false);
  const [ResolutionUpload, setResolutionUpload] = React.useState(false);
  const [BankStatementUpload, setBankStatementUpload] = React.useState(false);

  // User
  const [declineTAXNumberUpload, setDeclinedTAXNumberUpload] = React.useState(false);
  const [declineTAXUploadUpload, setDeclinedTAXUploadUpload] = React.useState(false);

  const [TAXNumberUpload, setTAXNumberUpload] = React.useState(false);
  const [TAXUploadUpload, setTAXUploadUpload] = React.useState(false);


  // Combined
  const [declineSourceOfFundsUpload, setDeclinedSourceOfFundsUpload] = React.useState(false);
  const [declineUserUploadsUpload, setDeclinedUserUploadsUpload] = React.useState(false);

  const [SourceOfFundsUpload, setSourceOfFundsUpload] = React.useState(false);
  const [UserUploadsUpload, setUserUploadsUpload] = React.useState(false);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Profile" breadcrumbItem="Profile" />

          <Row>
            <Col md="8" sm="12">
              {personalLoading ?
                <Spinner type="grow" className="ms-2" color="success" />
                :
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Personal Information</CardTitle>

                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Userid :</th>
                            <td>{typeof userInfo.userid !== undefined ?
                              userInfo.userid
                              : ''}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{typeof userInfo.lastname !== undefined ?
                              userInfo.name + userInfo.lastname
                              : ''}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{typeof userInfo.cell !== undefined ? userInfo.cell : ''}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{typeof userInfo.email !== undefined ? userInfo.email : ''}</td>
                          </tr>

                          {balanceLoading ?
                            <Spinner type="grow" className="ms-2" color="success" />
                            :
                            <div>
                              <tr>
                                <th scope="row">Available Wallet Balance :</th>

                              </tr>

                              <tr>
                                <td>USDT {usdtWalletet.toFixed(2)}</td>
                              </tr>

                              <tr>
                                <td>BTC {btcWalletet.toFixed(6)}</td>
                              </tr>

                              <tr>
                                <td>ZAR {zarWalletet.toFixed(2)}</td>
                              </tr>

                              <br />
                              <tr>
                                <th scope="row">Total In Products :</th>
                              </tr>

                              <tr>
                                <td>USDT {usdtProduct.toFixed(2)}</td>
                              </tr>

                              <tr>
                                <td>ZAR {zarProduct.toFixed(2)}</td>
                              </tr>
                            </div>}

                        </tbody>
                      </Table>

                    </div>
                  </CardBody>
                </Card>}

              {personalLoading ?
                <Spinner type="grow" className="ms-2" color="success" />
                :
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Account Status</CardTitle>

                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Account Status :</th>
                            <td>{typeof userInfo.u_status !== undefined ?
                              userInfo.u_status === 'A' ? 'Active' : userInfo.u_status === 'P' ? 'FICA Required' : userInfo.u_status === 'L' ? 'Email Confirmation Required' : 'Locked'
                              : ''}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">FICA Status :</th>
                            <td>{typeof userInfo.fica_status !== undefined ?
                              userInfo.fica_status === 'A' ? 'Approved' : userInfo.fica_status === 'P' ? 'Waiting For Admin Approval' : 'FICA Required'
                              : ''}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Auto Renew Products :</th>
                            <td>{typeof userInfo.autoRenew !== undefined ?
                              userInfo.autoRenew ? 'Enabled' : 'Disabled'
                              : ''}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Use Wallet Balance To AutoRenew :</th>
                            <td>{typeof userInfo.autoRenewBalance !== undefined ?
                              userInfo.autoRenewBalance ? 'Enabled' : 'Disabled'
                              : ''}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">2FA :</th>
                            <td>{typeof userInfo.auth_secret !== undefined ?
                              userInfo.auth_secret ? 'Enabled' : 'Disabled'
                              : ''}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Bank Confirmation Status :</th>
                            <td>{typeof userProfileInfo.bank_status !== undefined ?
                              userProfileInfo.bank_status
                              : ''}
                            </td>
                          </tr>

                          {userProfileInfo.bank_account_name ?
                            <div>

                              <tr>
                                <th scope="row">Bank Name :</th>
                                <td>{userProfileInfo.bank_bank_name}
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Account Name :</th>
                                <td>{userProfileInfo.bank_account_name}
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Account Number :</th>
                                <td>{userProfileInfo.bank_account_number}
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Account Type :</th>
                                <td>{userProfileInfo.bank_account_type}
                                </td>
                              </tr>

                              
                              <tr>
                                <th scope="row">Branch Name :</th>
                                <td>{userProfileInfo.branch_name ? userProfileInfo.branch_name : "Require Branch Name"}
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Branch Code :</th>
                                <td>{userProfileInfo.bank_branch_code ? userProfileInfo.bank_branch_code : "Require Branch Code"}
                                </td>
                              </tr>

                              <p>Bank Upload:</p>
                                {userProfileInfo && userProfileInfo.bank_upload ?

                                <div>
                    
                                {(userProfileInfo.bank_upload && !userProfileInfo.bank_upload.includes('.png') && !userProfileInfo.bank_upload.includes('.jpeg') && !userProfileInfo.bank_upload.includes('.jpg') )?
                                <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.bank_upload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                :
                                <Zoom><img src={bankUpload} className="w-25 img-thumbnail"/></Zoom> }
                                      </div>
                                  
                                  :
                                    <p className="text-danger">No Bank Upload</p>
                                }


                            </div>

                            : ''}

                        </tbody>
                      </Table>

                    </div>
                  </CardBody>
                </Card>}

              {accountStatusLoading ?
                <Spinner type="grow" className="ms-2" color="success" />
                :
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Products</CardTitle>

                    <div className="table-responsive">
                      {allProducts.map(product => (
                        <div key={product.productid}>
                          <input
                            type="checkbox"
                            disabled={adminUser === 'false' ? 'disabled' : ''}
                            id={product.productid}
                            checked={selectedProducts.includes(product.productid)}
                            onClick={() => handleProductSelection(product.productid)}
                          />
                          <label htmlFor={product.productid}>{product.name}</label>
                        </div>
                      ))}

                    </div>

                    <Button disabled={adminUser === 'false' ? 'disabled' : ''} onClick={(e) => { submitProductSelection(userInfo.userid) }} >Submit</Button>
                  </CardBody>
                </Card>}

              {notificationsLoading ?
                <Spinner type="grow" className="ms-2" color="success" />
                :
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Send Notification:</CardTitle>

                    <div className="table-responsive">
                      <label>Title:</label><br />
                      <input
                        className="form-control"
                        type="text"
                        value={notificationTitle}
                        onChange={(e) => { changeNotificationTitle(e.target.value) }}
                      /><br />
                      <label>Description:</label><br />
                      <textarea
                        className="form-control"
                        value={notificationDescription}
                        onChange={(e) => { changeNotificationDescription(e.target.value) }}
                      ></textarea><br />
                      <Button onClick={(e) => { sendNotification(userInfo.userid) }} >Submit</Button>


                    </div>
                  </CardBody>
                </Card>}



              {userRole === 'super' ?
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">KYC Information</CardTitle>

                    <div className="table-responsive">
                      <label>ID Number:</label><br />
                      <input
                        type="text"
                        className="form-control"
                        value={userProfileInfo.id_num}
                        disabled
                      /><br />
                      <label>DOB:</label><br />
                      <input
                        type="text"
                        className="form-control"
                        value={userProfileInfo.dob}
                        disabled
                      /><br />
                      <label>Address:</label><br />
                      <input
                        type="text"
                        className="form-control"
                        value={userProfileInfo.address}
                        disabled
                      /><br />

                      <Table className="table-nowrap mb-0">
                        <tbody>

                          <tr>
                            <th scope="row">Account Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">
                                  <select
                                    name="currency"
                                    className="form-control"
                                    placeholder="Currency"
                                    type="date"
                                    onChange={(e) => setUStatus(e.target.value)}
                                    value={uStatus}
                                  // value={newCurrency}
                                  >
                                    {optionGroup.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >{option.label}
                                      </option>

                                    ))}

                                  </select>

                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">KYC Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">

                                  <select
                                    name="currency"
                                    className="form-control"
                                    placeholder="Currency"
                                    type="date"
                                    onChange={(e) => setKYCStatus(e.target.value)}
                                    value={kycStatus}
                                  // value={newCurrency}
                                  >
                                    {ficaStatus.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >{option.label}
                                      </option>

                                    ))}

                                  </select>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Payment Status :</th>
                            <td>
                              <div className="mb-3">
                                <div className="col-md-10">


                                  <select
                                    name="currency"
                                    className="form-control"
                                    placeholder="Currency"
                                    type="date"
                                    onChange={(e) => setPayment(e.target.value)}
                                    value={payment}
                                  // value={newCurrency}
                                  >
                                    {paymentOption.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >{option.label}
                                      </option>

                                    ))}

                                  </select>


                                </div>
                              </div>
                            </td>
                          </tr>

                          {/* <tr>
                          <th scope="row">VIP :</th>
                          <td>
                              <div className="mb-3">
                                <div className="col-md-10">

                                    <select
                                        name="currency"
                                        className="form-control"
                                        placeholder="Currency"
                                        type="date"
                                        onChange={(e)=>setVIP(e.target.value)}
                                        value={vip}
                                        // value={newCurrency}
                                    >
                                      {vipOption.map(option => (
                                        <option
                                        key={option.value}
                                        value={option.value}
                                        >{option.label}
                                        </option>

                                      ))}
                                      
                                    </select>


                                </div>
                              </div>
                            </td>
                        </tr> */}

                        </tbody>
                      </Table>


                      <th scope="row">Uploads</th>
                      {/* const[declineIDUpload, setDeclineIDUpload] =  React.useState(false);
      const[declineAddressUpload, setDeclineAddressUpload] =  React.useState(false);
      const[declineCIPCCUpload, setDeclineCIPCCUpload] =  React.useState(false); */}

                      {declinedUploads ?
                        <div>
                          <p>Notes For Declining Uploads:</p>
                          <textarea value={declinedNotes} className="form-control" onChange={(e) => { onNotesChanges(e.target.value) }}>

                          </textarea>

                          <Button className="mt-4 mb-4 bg-success" onClick={(e) => { declineUploads(userInfo.userid) }} >Save</Button>
                        </div>
                        : ''}

                      <p>ID Upload:</p>

                      {declineIDUpload ?
                        <p className="text-danger">Removed ID Upload</p>
                        :
                        <div>
                          <button value="id" onClick={(e) => { clickedDeclineButton("id") }} style={{ padding: "unset", border: "unset" }}><i className="fas fa-times-circle bg-red" style={{ fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px" }}></i></button>
                          {(userProfileInfo.idupload && !userProfileInfo.idupload.includes('.png') && !userProfileInfo.idupload.includes('.jpeg') && !userProfileInfo.idupload.includes('.jpg')) ?
                            // <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+userProfileInfo.idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                            <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/' + userProfileInfo.idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                            :
                            <Zoom><img src={idupload} className="w-25 img-thumbnail" /></Zoom>}

                          <div className="mt-2">
                            {
                              // console.log("the user profile we are referring to is: ")
                              (userProfileInfo.idupload2 && !userProfileInfo.idupload2.includes('.png') && !userProfileInfo.idupload2.includes('.jpeg') && !userProfileInfo.idupload2.includes('.jpg')) ?
                                // <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_BASE + 'assets/'+userProfileInfo.idupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/' + userProfileInfo.idupload2} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                :
                                <Zoom><img src={idupload2} className="w-25 img-thumbnail" /></Zoom>}


                          </div>
                        </div>
                        //<DisplayUploads value="id" onClickFunction={clickedDeclineButton} upload={userProfileInfo.idupload} uploadImage={idupload} />
                      }

                      <p>Proof Of Address Upload:</p>
                      {declineAddressUpload ?
                        <p className="text-danger">Removed Proof Of Address Upload</p>
                        :
                        //     <div>
                        //  <button value="poa" onClick={(e) => {clickedDeclineButton("poa")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                        //  {(userProfileInfo.poaupload && !userProfileInfo.poaupload.includes('.png') && !userProfileInfo.poaupload.includes('.jpeg') && !userProfileInfo.poaupload.includes('.jpg') )?
                        //   <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.poaupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                        //  :
                        //  <Zoom><img src={poaupload} className="w-25 img-thumbnail"/></Zoom> }
                        //         </div>

                        <DisplayUploads value="poa" onClickFunction={clickedDeclineButton} upload={userProfileInfo.poaupload} uploadImage={poaupload} />


                      }





                      {userInfo.usertype == '1' || userInfo.usertype == 1 ?
                        <div>
                          {/* CIPC */}
                          <div>
                            <p>CIPC:</p>

                            {declineCIPCCUpload ?
                              <p className="text-danger">Removed CIPCC Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayUploads value="cipc" onClickFunction={clickedDeclineButton} upload={userProfileInfo.cipcupload} uploadImage={cipcupload} />
                              </div>
                            }



                            <p>CIPC Nr: {cipc}</p>
                          </div>

                          {/* cm2_upload */}
                          <div>
                            <p>CM2:</p>

                            {declineCM2Upload ?
                              <p className="text-danger">Removed CM2 Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="cm2" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.cm2_upload}  />
                              </div>
                            }

                          </div>

                          {/* cm22_upload */}
                          <div>
                            <p>CM22:</p>

                            {declineCM22Upload ?
                              <p className="text-danger">Removed CM22 Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="cm22" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.cm22_upload}  />
                              </div>
                            }

                          </div>

                          {/* cm9_upload */}
                          <div>
                            <p>CM9:</p>

                            {declineCM9Upload ?
                              <p className="text-danger">Removed CM9 Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="cm9" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.cm9_cor_upload}  />
                              </div>
                            }

                          </div>

                          {/* business_residence_upload */}
                          <div>
                            <p>Business Residence:</p>

                            {declineBusinessResidenceUpload ?
                              <p className="text-danger">Removed Business Residence Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="BusinessResidence" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.business_residence_upload}  />
                              </div>
                            }

                          </div>

                          {/* business_name_documentation_upload */}
                          <div>
                            <p>Business Name Documentation:</p>

                            {declineBusinessNameDocumentationUpload ?
                              <p className="text-danger">Removed Business Name Documentation Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="BusinessNameDocumentation" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.business_name_documentation_upload}  />
                              </div>
                            }

                          </div>

                          {/* tax_registrations_upload */}
                          <div>
                            <p>Tax Registrations:</p>

                            {declineTAXRegistrationsUpload ?
                              <p className="text-danger">Removed Tax Registrations Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="TAXRegistrations" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.tax_registrations_upload}  />
                              </div>
                            }

                          </div>

                          {/* resolution_upload */}
                          <div>
                            <p>Resolution:</p>

                            {declineResolutionUpload ?
                              <p className="text-danger">Removed Resolution Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="Resolution" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.resolution_upload}  />
                              </div>
                            }

                          </div>

                          {/* bank_statement_upload */}
                          <div>
                            <p>Bank Statement:</p>

                            {declineBankStatementUpload ?
                              <p className="text-danger">Removed Bank Statement Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                <DisplayMultipleUploads value="BankStatement" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.bank_statement_upload}  />
                              </div>
                            }

                          </div>


                          {/* cm29_upload */}
                          <div>
                            <p>CM29:</p>

                            {!userProfileInfo.cm29_upload ?
                              <text className="text-danger">Not Uploaded!</text>
                            :
                            declineCM29Upload ?
                              <p className="text-danger">Removed CM29 Upload</p>
                              :
                              <div>
                                <DisplayMultipleUploads value="cm29" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.cm29_upload}  />
                              
                              </div>
                            }

                          </div>


                        </div>
                        : ''}

                          {/* source_of_funds_upload */}
                          <div>
                            <p>Source Of Funds:</p>

                            {declineSourceOfFundsUpload ?
                              <p className="text-danger">Removed Source Of Funds Upload</p>
                              :
                              <div>
                                {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                  {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                    <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                  :
                                  <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                      <button value={"SourceOfFunds"} onClick={(e) => clickedDeclineButton("SourceOfFunds")} style={{ padding: "unset", border: "unset" }}>
                                        <i className="fas fa-times-circle bg-red" style={{ fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px" }}></i>
                                      </button>
                                {/* <DisplayUploads value="SourceOfFunds" onClickFunction={clickedDeclineButton} upload={userProfileInfo.source_of_funds_upload} uploadImage={SourceOfFundsUpload} /> */}
                                <p className="text-danger">{userProfileInfo.source_of_funds_upload}</p>
                              </div>
                            }

                          </div>

                          {/* users_upload */}
                          <div>
                            <p>User Uploads:</p>

                            {declineUserUploadsUpload ?
                              <p className="text-danger">Removed User Uploads Upload</p>
                              :
                              <div>
                                <DisplayMultipleUploads value="UserUploads" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.user_uploads} />
                              </div>
                            }

                          </div>

                          {userInfo.usertype == '0' || userInfo.usertype == 0 ?
                          <div>
                              {/* tax_number_upload */}
                              <div>
                                <p>Tax Number:</p>

                                {!userProfileInfo.tax_number?
                                     <text className="text-danger">Not Uploaded!</text>
                                :
                                declineTAXNumberUpload ?
                                  <p className="text-danger">Removed Tax Number Upload</p>
                                  :
                                  <div>
                                      <button value={"TAXNumber"} onClick={(e) => clickedDeclineButton("TAXNumber")} style={{ padding: "unset", border: "unset" }}>
                                        <i className="fas fa-times-circle bg-red" style={{ fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px" }}></i>
                                      </button>
                                    {/* <DisplayUploads value="TAXNumber" onClickFunction={clickedDeclineButton} upload={userProfileInfo.tax_number} uploadImage={TAXNumberUpload} /> */}
                                    <p className="text-danger">{userProfileInfo.tax_number ? userProfileInfo.tax_number : "Not Uploaded!"}</p>
                                  </div>
                                  
                                }

                            </div>

                            {/* tax_upload */}
                            <div>
                              <p>Tax Uploads:</p>

                              {!userProfileInfo.tax_upload ?
                                <text className="text-danger">Not Uploaded!</text>
                              :declineTAXUploadUpload ?
                                <p className="text-danger">Removed Tax Upload</p>
                                :
                                <div>
                                  {/* <button value="cipc" onClick={(e) => {clickedDeclineButton("cipc")}} style={{padding: "unset", border: "unset"}}><i className="fas fa-times-circle bg-red" style={{fontSize: "20px", color: "red", marginRight: "15px", paddingTop: "5px"}}></i></button>
                                    {(userProfileInfo.cipcupload && !userProfileInfo.cipcupload.includes('.png') && !userProfileInfo.cipcupload.includes('.jpeg') && !userProfileInfo.cipcupload.includes('.jpg') )?
                                      <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_BASE_URL + 'getpdf2/'+userProfileInfo.cipcupload} className="btn badge bg-dark badge-pill mb-1 font-size-16">Download</a>
                                    :
                                    <Zoom><img src={cipcupload} className="w-25 img-thumbnail"/></Zoom> } */}
                                  <DisplayMultipleUploads value="TAXUpload" onClickFunction={clickedDeclineButton} uploads={userProfileInfo.tax_upload}  />
                                </div>
                              }

                            </div>
                          </div>
                          : ''}


                      <br />

                      <Button className="mt-4" onClick={(e) => { approveKYC(userInfo.userid) }} >Submit</Button>


                    </div>
                  </CardBody>
                </Card>
                : ''}
            </Col>


            <Col xl="4" sm="6">
              {!displayUserSpinner ?
                <div>

                  <div className="mt-2">
                    <a href={"../user-deposits/" + userInfo.userid}>
                      <Button><h5 className="font-size-15 text-white ">
                        Deposits
                      </h5></Button>
                    </a>
                  </div>

                  <div className="mt-2">
                    <a href={"../user-withdrawals/" + userInfo.userid}>
                      <Button><h5 className="font-size-15 text-white ">
                        Withdrawals
                      </h5></Button>
                    </a>
                  </div>

                  <div className="mt-2">
                    <a href={"../user-products/" + userInfo.userid}>
                      <Button><h5 className="font-size-15 text-white  ">
                        Products
                      </h5></Button>
                    </a>
                  </div>

                  <div className="mt-2">
                    <a href={"../user-transactions/" + userInfo.userid}>
                      <Button><h5 className="font-size-15 text-white  ">
                        Transactions
                      </h5></Button>
                    </a>
                  </div>
                </div>
                :
                <Spinner type="grow" className="ms-2" color="success" />
              }
            </Col>


          </Row>
        </Container>
      </div>
    </React.Fragment>

  )
}

export default EditUser